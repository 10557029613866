/* eslint-disable no-restricted-globals */

import React, { useState } from 'react';

import './App.css';

import SearchBar from './SearchBar';

import { Link } from 'react-router-dom';

import DynamicTitle from './DynamicTitle';

import Version from './Version' ;

import Dev from './Dev';

import SarsCoV2023Paper from './SarsCoV2023Paper';

function App() {
  
  const currentHost = window.location.hostname;

  if (currentHost.split('.').length > 2 ) {

    const newHost = currentHost.split('.')[1]+'.'+currentHost.split('.')[2];
    
    //window.location.hostname = newHost;

  }

  let subDomain = '';

  if (currentHost.split('.').length > 2 ) {

    subDomain = currentHost.split('.')[0];

  }

  let sarsCov2Link = ''

  if (currentHost.split('.').length === 2 ) {

    sarsCov2Link = window.location.protocol+'//sarscov2.'+currentHost.split('/')[0].split('.')[0]+'.'+currentHost.split('/')[0].split('.')[1];
  
  } else {

    sarsCov2Link = 'sarscov2.spatiomics.org';

  }

  return (
    <>

      <div className="App">

        <header className="App-header">

          <DynamicTitle />

        </header>

      </div>

      <SearchBar />

      <div className="subtitle">Formatted Resources</div>

      <div className="link">

        <Link to={sarsCov2Link}>SARS-CoV-2 ORF3a and M interactome list</Link>

      </div> 

      <div className="subtitle">About Spatiomics.org</div>

      <div className="link">
        
        <a href="#">Browse super-resolution-proximity-labeling data of your proteins of interest.</a>
      
      </div> 
      
      <div className="subtitle">Contributing Groups</div>

      <div className="link">

        <a href="https://sites.google.com/view/rheesnu" target="_blank" rel="noreferrer" >Rhee lab</a>
      
      </div>

      <div className="link">

        <a href="https://biosci.snu.ac.kr/proteomics/" target="_blank" rel="noreferrer" >Systems Mass Spectrometry Lab</a>
      
      </div>

      <div className="subtitle">Publications</div>

      <div className="link">

        <a href="#" 
        className="disabled"
        style={{
          stroke: 'black',
          fontSize: 18,
          fontFamily: 'Arial',
          marginLeft: 50,
          marginRight: 50,
        }}>
          MitoAtlas (Manuscript in preparation)
        </a>

      </div>

      <SarsCoV2023Paper />

      <Dev />

      <Version />
      
      </>
  );
}

export default App;
