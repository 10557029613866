/* eslint-disable no-restricted-globals */
import axios from 'axios';
import React, { useState, useEffect} from 'react';
import './MitoAtlasGeneral.css';
import getcolor from './getcolor';
import SubERSvg from './SubERSvg';
import ERTopologySVG from './ERTopologySVG';
import ERDomainSVG from './ERDomainSVG';

function ERGeneral({style, 
  genename, 
  proteinname, 
  uniprotID , 
  LocalER ,
  LocalUniProt,
  tm_manual,
  tm_uniprot,
  tm_deeptmhmm,
  tm_tmbed,
  sequence,
  domain}){

    const [apex2sites, setApex2sites] = useState([]);
    const [turboidsites, setTurboidsites] = useState([]);
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isdata, setIsdata] = useState(false)
    const source = axios.CancelToken.source();

    const bgcolorBioID = getcolor(style,'BioID','lighthex');
    const bgcolorAPEX2 = getcolor(style,'APEX2','lighthex');

    useEffect(() => {
        const study='er';
        setLoading(true);
        const queries=[
          axios.get(`https://spatiomics.org/api/pldata?study=${study}&uid=${uniprotID}&enzyme=apex2&sitefeature=LocalER`),
          axios.get(`https://spatiomics.org/api/pldata?study=${study}&uid=${uniprotID}&enzyme=turboid&sitefeature=LocalER`),
        ];
        console.log(queries);
        Promise.all(queries)
          .then(([response1,response2]) => {
            console.log('apex2_sites')
            console.log(response1.data);
            console.log('turboid_sites');
            console.log(response2.data);
            setApex2sites(response1.data);
            setTurboidsites(response2.data);
            //sort sites by site number
            let apex2Sites = response1.data.sort((a, b) => {
              const siteIdA = parseInt(a.siteID.split('_')[1]);
              const siteIdB = parseInt(b.siteID.split('_')[1]);
            
              return siteIdA - siteIdB;
            });

            apex2Sites.forEach(obj=>{
              obj.enzyme='APEX2';
              obj.site=parseInt(obj.siteID.split('_')[1]);
            })
            //sort sites by site number
            let turboidSites = response2.data.sort((a, b) => {
              const siteIdA = parseInt(a.siteID.split('_')[1]);
              const siteIdB = parseInt(b.siteID.split('_')[1]);
            
              return siteIdA - siteIdB;
            });
            
            turboidSites.forEach(obj=>{
              obj.enzyme = 'TurboID';
              obj.site = parseInt(obj.siteID.split('_')[1]);
            })
            
            setSites(apex2Sites.concat(turboidSites).sort((a, b) => a.site - b.site));
            
            console.log([ ...apex2Sites, ...turboidSites ]);

            if(apex2Sites.length + turboidSites.length > 0){ 

              setIsdata(true);

            }
          })
          .catch(error => {
            console.error(`there was an error communicating to the API: ${error}`);
          })
          .finally(() => {
            setLoading(false);
          });
        return () => {
          source.cancel();
        };
      }, [uniprotID]);

    if (loading) {
    return <div>Loading...</div>;
    }
    if (!isdata) {
        return <div>Sorry! no data!</div>;
    }

    return    <table class="general-properties-table">
    <tbody class="tbody">
    <tr class="row">
      <td class="column"><strong>Protein localization:</strong></td>
      <td class="column">
      <SubERSvg style={style}
      LocalER={LocalER}
      genename={genename}
      tm_manual={tm_manual}
      tm_uniprot={tm_uniprot}
      tm_deeptmhmm={tm_deeptmhmm}
      tm_tmbed={tm_tmbed}/>
      </td>
    </tr>
    <tr class="row">
      <td class="column"><strong>Domain architecture:</strong></td>
      <td class="column">
        <ERDomainSVG style={style}
        LocalER={LocalER}
        genename={genename}
        tm_manual={tm_manual}
        tm_uniprot={tm_uniprot}
        tm_deeptmhmm={tm_deeptmhmm}
        tm_tmbed={tm_tmbed}
        domain={domain}
        sites={sites}
        sequence={sequence}/>
    
      </td>
    </tr>
      <tr class="row">
        <td class="column"><strong>Membrane topology:</strong></td>
        <td class="column">

        <ERTopologySVG 
        style={style}
        LocalER={LocalER}
        genename={genename}
        tm_manual={tm_manual}
        tm_uniprot={tm_uniprot}
        tm_deeptmhmm={tm_deeptmhmm}
        tm_tmbed={tm_tmbed}
        sequence={sequence}
        sites={sites}/>
        
        </td>
      </tr>
      {tm_manual.length>0 &&(
      <tr class="row">
        <td class="column"><strong>TM domains, Manual:</strong></td>
        <td class="column">
        {
            tm_manual.map((d,i)=>{
                return (<span>
                        {d.start&&
                        <span>
                        {d.start}-{d.end}
                        </span>
                        }{i < tm_manual.length - 1 && ", "}
                </span>)
            })
            
        }
        </td>
      </tr>
      )}
      {tm_uniprot.length>0 &&(
      <tr class="row">
        <td class="column"><strong>TM domains, UniProt:</strong></td>
        <td class="column">
        {
            tm_uniprot.map((d,i)=>{
                return (<span>
                        {d.start&&
                        <span>
                        {d.start}-{d.end}{"("}{d.type}{")"}
                        </span>
                        }{i < tm_uniprot.length - 1 && ", "}
                </span>)
            })
            
        }
        </td>
      </tr>
      )}
      {tm_deeptmhmm.length>0 &&(
      <tr class="row">
        <td class="column"><strong>TM domains, DeepTMHMM:</strong></td>
        <td class="column">
        {
            tm_deeptmhmm.map((d,i)=>{
                return (<span>
                        {d.start&&
                        <span>
                        {d.start}-{d.end}{"("}{d.type}{")"}
                        </span>
                        }{i < tm_deeptmhmm.length - 1 && ", "}
                </span>)
            })
            
        }
        </td>
      </tr>
      )}
      {tm_tmbed.length>0 &&(
      <tr class="row">
        <td class="column"><strong>TM domains, TMbed:</strong></td>
        <td class="column">
        {
            tm_tmbed.map((d,i)=>{
                return (<span>
                        {d.start&&
                        <span>
                        {d.start}-{d.end}{"("}{d.type.split("_")[0]}{")"}
                        </span>
                        }{i < tm_tmbed.length - 1 && ", "}
                </span>)
            })
            
        }
        </td>
      </tr>
      )}
      <tr class="row">
        <td class="column"><strong>Organism:</strong></td>
        <td class="column"><em>Homo sapiens</em></td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Protein name, UniProt:</strong></td>
        <td class="column">{proteinname}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Gene name, UniProt:</strong></td>
        <td class="column">{genename}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>UniProt ID:</strong></td>
        <td class="column"><a href={`https://www.uniprot.org/uniprotkb/${uniprotID}/entry`}
          target="_blank" rel="noreferrer">{uniprotID}</a></td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Localization, This study:</strong></td>
        <td class="column">{LocalER}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong>Localization, Uniprot:</strong></td>
        <td class="column">{LocalUniProt}</td>
      </tr>
      <tr class="row">
        <td class="column"><strong># of total labeled sites:</strong></td>
        <td class="column">{apex2sites.length+turboidsites.length}</td>
      </tr>
      <tr class="row">
        <td class="column">
          <strong># of 
            <span style={{ backgroundColor: bgcolorAPEX2
                        }}>APEX2
            </span> labeled sites:
          </strong>
        </td>
        <td class="column">{apex2sites.length}</td>
      </tr>
      {apex2sites.length>0 &&
      <tr class="row">
        <td class="column">
          <strong>positions of 
            <span style={{ backgroundColor: bgcolorAPEX2
                        }}>APEX2
            </span> labeled sites:
          </strong>
        </td>
        <td class="column">
        {
            apex2sites.map((d,i)=>{
                return (<span>
                        {d.siteID &&
                        <span style={{ backgroundColor: bgcolorAPEX2
                        }}>
                        Y{d.siteID.split('_')[1]}
                        </span>
                        }{i < apex2sites.length - 1 && ", "}
                </span>
                )
            })
        }
        </td>
      </tr>}
      <tr class="row">
        <td class="column"><strong># of <span style={{ backgroundColor: bgcolorBioID
                        }}>TurboID</span> labeled sites:</strong></td>
        <td class="column">{turboidsites.length}</td>
      </tr>
      {turboidsites.length>0 &&
      <tr class="row">
        <td class="column"><strong>positions of <span style={{ backgroundColor: bgcolorBioID
                        }}>TurboID</span> labeled sites:</strong></td>
        <td class="column">
            {
            turboidsites.map((d,i)=>{
                return (<span>
                        {d.siteID &&
                        <span style={{ backgroundColor: bgcolorBioID
                        }}>
                        K{d.siteID.split('_')[1]}
                        </span>
                        }{i < turboidsites.length - 1 && ", "}
                </span>
                )
            })
        }
        </td>
      </tr>}
      <tr class="row">
        <td class="column"><strong>Localizations:</strong></td>
        <td class="column" 
        style={{whiteSpace: "pre-line"}}>
            {sites.map(d => {

      //let bgcolorEnzyme = style.find(obj=>obj.hasOwnProperty('category')&&obj.category===d.enzyme).lighthex;

      //let bgcolorLocalization = style.find(obj=>obj.hasOwnProperty('category')&&obj.category===d.LocalER).lighthex;

      let bgcolorEnzyme = '#BBBBBB'

      let bgcolorLocalization = '#BBBBBB'

      let site = d.siteID.split('_')[1];
      if( d.enzyme === 'APEX2' ){
        site = 'Y' + site ;
      } else if ( d.enzyme === 'TurboID' ){
        site = 'K' + site ;
      }
      let localization= d.LocalER;

      return (
        <span>
            <span style={{ backgroundColor: bgcolorEnzyme 
            }}>
              {site}
            </span>
            : 
            <span style={{ backgroundColor: bgcolorLocalization 
            }}>
                {localization}
            </span>
          <br/>
        </span>
      );
    })}
        </td>
      </tr>
    </tbody>
  </table>
}

export default ERGeneral;