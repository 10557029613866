import React, { useState, useEffect } from 'react';

import RadarAnimation from './components/RadarAnimation.js';

import SarsCovTitle from './components/SarsCovTitle.js';

import MitoAtlasTitle from './components/MitoAtlasTitle.js';

import MoimTitle from './components/MoimTitle.js';

import ERTitle from './components/ERTitle.js';

import { Link } from 'react-router-dom';

export default function DynamicTitle(){

    const currentHost = window.location.hostname;

    let subDomain = '';

    if (currentHost.split('.').length >= 2 ) {

        subDomain = currentHost.split('.')[0];

    }

    let exactRoot = ''

    if (currentHost.split('.').length === 3 ) {

        exactRoot = window.location.protocol+'//'+currentHost.split('/')[0].split('.')[1]+'.'+currentHost.split('/')[0].split('.')[2];
        console.log(exactRoot);
    }

    const [hoveredLink, setHoveredLink] = useState(false);

    const handleMouseEnter = () => {

        setHoveredLink(true);

    };

    const handleMouseLeave = () => {

        setHoveredLink(false);

    };

    const linkStyle = {
        
        left: 0,

        top: 0,
        
        position:'absolute',
      
        margin : 10,

        fontFamily : 'Arial',
        
        fontSize : 15,
        
        };

    const hoverLinkStyle = {
        
        left: 0,

        top: 0,
        
        position:'absolute',
        
        margin : 10,

        color: 'red',

        textDecoration: 'underline',

        fontFamily : 'Arial',
        
        fontSize : 15,
        
        };

    useEffect(() => {

        if (subDomain !== 'sarscov2' && subDomain !== 'mitoatlas' && subDomain !== 'moim'){

            document.title = "Spatiomics";

        } else if (subDomain === 'sarscov2'){

            document.title = "SARS-CoV-2";

        } else if (subDomain === 'mitoatlas'){

            document.title = "MitoAtlas";

        } else if (subDomain === 'moim'){

            document.title = "Moim";

        } else if (subDomain === 'er'){

            document.title = "ER";

        }

        }, [subDomain]);

    return (
    
    <>
        { subDomain === 'sarscov2' &&

        <Link to = { exactRoot }

        style = { hoveredLink ? hoverLinkStyle : linkStyle }

        onMouseEnter = {handleMouseEnter}

        onMouseLeave = {handleMouseLeave}

        > go back to spatiomics.org 

        </Link>

        }
        
        <Link to={`/`}>

        { subDomain !== 'sarscov2' && subDomain !== 'mitoatlas' && subDomain !== 'moim' && subDomain !== 'er' &&

        <RadarAnimation />}

        { subDomain === 'sarscov2' &&

        <SarsCovTitle />

        }

        { subDomain === 'mitoatlas' &&

        <MitoAtlasTitle />

        }

        { subDomain === 'moim' &&

        <MoimTitle />

        }
        { subDomain === 'er' &&

        <ERTitle />

        }

        </Link>

    </>

    )
    

}