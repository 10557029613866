/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import './ProteinDetails.css';
import GeneralProperties from './GeneralProperties';
import IBAQ from './iBAQ';
import Heatmap from './Heatmap'
import SeqExplorer from './SeqExplorer'
import MitoAtlasGeneral from './MitoAtlasGeneral'
import SarsCov2General from './SarsCov2General'
import ICn3D from './iCn3D'
import parseTM from './parseTM';
import PubMedSearch from './PubMedSearch';
import DynamicTitle from './DynamicTitle';

function ProteinDetails() {
  const currentHost = window.location.hostname;
  const navigate = useNavigate();
  const [protein, setProtein] = useState([]);
  const [length, setLength] = useState(0);
  const [style, setStyle] = useState([]);
  const { uid } = useParams();
  const [loading, setLoading] = useState(false);

  const [selectedTab, setSelectedTab] = useState('mitoatlas');
  
  const [selectedDropdown, setSelectedDropdown] = useState('mitoatlas_general');
  const source = axios.CancelToken.source();
  
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
    setSelectedDropdown('');
  };

  const handleDropdownClick = (dropdownName) => {
    setSelectedDropdown(dropdownName);
  }
  
  useEffect(() => {
    const debouncedQuery = debounce(() => {

    setLoading(true);
    const queries=[
      axios.get(`https://spatiomics.org/api/search?search=${uid}`,),
      axios.get('https://spatiomics.org/api/style',),
    ];

    Promise.all(queries)
          .then(([response,response2]) => {
            setProtein(response.data);
            setLength(response.data.length);
            setStyle(response2.data);
            //console.log('using style');
            //console.log(response2.data);
          })
          .catch(error => {
            console.error(`there was an error communicating to the API: ${error}`);
          })
          .finally(() => {
            setLoading(false);
            if (currentHost.split('.')[0]!=='sarscov2') {
              setSelectedTab('mitoatlas');
            }
          });
        }, 100);

        debouncedQuery();
        return () => {
          source.cancel();
        };
      }, [uid]);

        if (loading) {
        return <div>
            Loading...</div>;
        }
        else if (length>1){
            navigate('/searchresult', { state: { results: protein } });
        } else if (length==0){
            navigate('/searchresult', { state: { results: "notfound" } });
        }
        else  if (!protein) {
        return <div>
            Protein not found.</div>;
        } else {
        //console.log(`using following resource to draw this page`);
        //console.log(protein);
        
        const tm_manual=protein[0].TMdomain_manual;
        const tm_uniprot=protein[0].TMdomain_UniProt;
        const tm_deeptmhmm=protein[0].TMdomain_DeepTMHMM;
        const tm_tmbed=protein[0].TMdomain_Tmbed;
        const parsedTMmanual=parseTM('Manual',tm_manual);
        const parsedTMuniprot=parseTM('UniProt',tm_uniprot);
        const parsedTMtmbed=parseTM('TMbed',tm_tmbed);
        const parsedTMdeeptmhmm=parseTM('DeepTMHMM',tm_deeptmhmm);
        const domain = protein[0].IPR_domains;
          

        return (
            <>
            <div className="App">
        <div className="App-header">
          <DynamicTitle />
        </div>

        </div>
        
        <SearchBar />
        
        <div className="title">
            {protein[0].Genename}
        </div>
        <div className="protein-details">
        <div className="tabs">

       
        
        {currentHost.split('.')[0]!=='sarscov2' && <button
          className={selectedTab === 'mitoatlas' ? 'active' : 'inactive'}
          onClick={() => {
            handleTabClick('mitoatlas');
            handleDropdownClick('mitoatlas_general');
          }}
        >
          MitoAtlas
        </button>}
        
        <div className={selectedTab === 'mitoatlas' ? 'dropdown-container show' : 'dropdown-container hide'}>
          <div className="dropdown">
            <button onClick={() => handleDropdownClick('mitoatlas_general')}>Summary</button>
            
            {/* 
            
            <button onClick={() => handleDropdownClick('mitoatlas_seq_explorer')}>Sequence explorer</button>

            */}

            <button onClick={() => handleDropdownClick('mitoatlas_apex2_heatmap')}>APEX2 Intensity Heatmap</button>
            <button onClick={() => handleDropdownClick('mitoatlas_bioid_heatmap')}>BioID Intensity Heatmap</button>
            <button onClick={() => handleDropdownClick('mitoatlas_icn3d')}>iCn3D viewer</button>
            <button onClick={() => handleDropdownClick('mitoatlas_pubmed')}>Eutils API PubMed search</button>
          </div>
        </div>

        {currentHost.split('.')[0]==='sarscov2' && <button
          className={selectedTab === 'sarscov2' ? 'active' : 'inactive'}
          onClick={() => {handleTabClick('sarscov2')
          handleDropdownClick('sarscov2_general')}
          }
        >
          SARS-CoV-2
        </button>}
            <div className={selectedTab === 'sarscov2' ? 'dropdown-container show' : 'dropdown-container hide'}>
            <div className="dropdown">
              <button onClick={() => handleDropdownClick('sarscov2_general')}>Summary</button>
              <button onClick={() => handleDropdownClick('sarscov2_heatmap')}>Heatmap</button>
              <button onClick={() => handleDropdownClick('sarscov2_icn3d')}>iCn3D viewer</button>
              <button onClick={() => handleDropdownClick('sarscov2_pubmed')}>Eutils API PubMed search</button>
            </div>
            </div>
        <button
          className={selectedTab === 'expression' ? 'active' : 'inactive'}
          onClick={() => handleTabClick('expression')}
        >
          Expression
        </button>
      </div>

      {selectedTab === 'general' && (
        <div className="content general-properties">
          <GeneralProperties 
          uniprotID={protein[0].UniProt_ID}
          proteinname={protein[0].Protein_name}
          genename={protein[0].Genename}  
          localization_mitoatlas={protein[0].Localization_mitoatlas}
          localization_uniprot={protein[0].Localization_uniprot}
          />
        </div>
      )}

      {selectedTab === 'expression' && (
        <div className="content expression">
          <IBAQ uniprotID={protein[0].UniProt_ID} genename={protein[0].Genename} />
        </div>
      )}

      {selectedTab === 'mitoatlas' && selectedDropdown === 'mitoatlas_general' &&(
        <div className="content mitoatlas-general">
        <MitoAtlasGeneral 
        style={style}
        uniprotID={protein[0].UniProt_ID} 
        proteinname={protein[0].Protein_name}
        genename={protein[0].Genename} 
        localization_mitoatlas={protein[0].Localization_mitoatlas}
        localization_uniprot={protein[0].Localization_uniprot}
        tm_manual={parsedTMmanual}
        tm_uniprot={parsedTMuniprot}
        tm_deeptmhmm={parsedTMdeeptmhmm}
        tm_tmbed={parsedTMtmbed}
        sequence={protein[0].Sequence}
        domain={domain} 
        />
      </div>
      )}

      {/* 
      
      {selectedTab === 'mitoatlas' && selectedDropdown === 'mitoatlas_seq_explorer' &&(
        <div className="content mitoatlas-seq-explorer">
        <SeqExplorer study='mitoatlas' 
        sequence={protein[0].Sequence} 
        uniprotID={protein[0].UniProt_ID} 
        genename={protein[0].Genename} 
        />
      </div>
      )}
      
      */}
      
      {selectedTab === 'mitoatlas' && selectedDropdown === 'mitoatlas_apex2_heatmap' &&(
        <div className="content mitoatlas-heatmap">
        <Heatmap style={style}
        study='mitoatlas' enzyme='APEX2' uniprotID={protein[0].UniProt_ID} genename={protein[0].Genename} />
      </div>
      )}
      {selectedTab === 'mitoatlas' && selectedDropdown === 'mitoatlas_bioid_heatmap' &&(
        <div className="content mitoatlas-heatmap">
        <Heatmap style={style}
        study='mitoatlas' enzyme='BioID' uniprotID={protein[0].UniProt_ID} genename={protein[0].Genename} />
      </div>
      )}

      {selectedTab === 'mitoatlas' && selectedDropdown === 'mitoatlas_icn3d' &&(
        <div className="content mitoatlas-heatmap">
        <ICn3D style={style}
        study='mitoatlas' 
        uniprotID={protein[0].UniProt_ID} 
        genename={protein[0].Genename}
        tm_manual={parsedTMmanual}
        tm_uniprot={parsedTMuniprot}
        tm_deeptmhmm={parsedTMdeeptmhmm}
        tm_tmbed={parsedTMtmbed}/>
      </div>
      )}

      {selectedTab === 'mitoatlas' && selectedDropdown === 'mitoatlas_pubmed' &&(
        <div className="content mitoatlas-heatmap">
        <PubMedSearch style={style}
        study='mitoatlas' 
        uniprotID={protein[0].UniProt_ID} 
        genename={protein[0].Genename}
        tm_manual={parsedTMmanual}
        tm_uniprot={parsedTMuniprot}
        tm_deeptmhmm={parsedTMdeeptmhmm}
        tm_tmbed={parsedTMtmbed}/>
      </div>
      )}

      {selectedTab === 'sarscov2' && selectedDropdown === 'sarscov2_general' &&(
        <div className="content mitoatlas-general">
        <SarsCov2General
        style={style}
        uniprotID={protein[0].UniProt_ID} 
        proteinname={protein[0].Protein_name}
        genename={protein[0].Genename} 
        localization_mitoatlas={protein[0].Localization_mitoatlas}
        localization_uniprot={protein[0].Localization_uniprot}
        />
      </div>
      )}
      {selectedTab === 'sarscov2' && selectedDropdown === 'sarscov2_heatmap' &&(
        <div className="content sarscov2-heatmap">
        <Heatmap style={style}
        study='sarscov2' enzyme='TurboID' uniprotID={protein[0].UniProt_ID} genename={protein[0].Genename} />
      </div>
      )}

      {selectedTab === 'sarscov2' && selectedDropdown === 'sarscov2_icn3d' &&(
        <div className="content mitoatlas-heatmap">
        <ICn3D style={style}
        study='sarscov2' 
        uniprotID={protein[0].UniProt_ID} 
        genename={protein[0].Genename}
        tm_manual={parsedTMmanual}
        tm_uniprot={parsedTMuniprot}
        tm_deeptmhmm={parsedTMdeeptmhmm}
        tm_tmbed={parsedTMtmbed}
        enzyme='TurboID'/>
      </div>
      )}

      {selectedTab === 'sarscov2' && selectedDropdown === 'sarscov2_pubmed' &&(
        <div className="content mitoatlas-heatmap">
        <PubMedSearch style={style}
        study='sarscov2' 
        uniprotID={protein[0].UniProt_ID} 
        genename={protein[0].Genename}
        tm_manual={parsedTMmanual}
        tm_uniprot={parsedTMuniprot}
        tm_deeptmhmm={parsedTMdeeptmhmm}
        tm_tmbed={parsedTMtmbed}/>
      </div>
      )}


      </div>
        </>
        );
        }
    }

export default ProteinDetails;
