import * as d3 from 'd3';
import  { ReactComponent as ERSVG }from './svg/er_sublocals_ver1.0.0.svg';
import getcolor from './getcolor';
import React, { useEffect , useRef } from 'react';
import saveButton from './saveButton';

export default function SubERSvg({style,LocalER,genename}){
        const svgRef = useRef(null);
        const saveRef = useRef(null);
        const btncontainerRef = useRef(null);
        useEffect(() => {
        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();
        console.log("creating subER graphic");
        
        // Define a color scale based on the 'sublocal' variable
        const labelPositions = [{'x':20,'y':20},
          {'x':4,'y':160},
          {'x':4,'y':80},
          {'x':4,'y': 45},
          {'x':4,'y': 10}];
        const labelColors = [getcolor(style,'inconclusive','lighthex'),
        getcolor(style,'Lumen','lighthex'),
        "url(#ERMGradient)",
        getcolor(style,'ERM peripheral (Cytosolic)','lighthex'),
        getcolor(style,'Cytosolic','lighthex'),
        getcolor(style,'inconclusive','lighthex')];
        // Create a linear gradient
        const defs= svg.append("defs");
        defs.append("linearGradient")
        .attr("id", "ERMGradient")
        .attr("x1", '0%')
        .attr("y1", '100%')
        .attr("x2", '0%')
        .attr("y2", '0%')
        .selectAll("stop")
        .data([
          {offset: "0%", color: labelColors[1]},
          {offset: "40%", color: labelColors[1]},
          {offset: "60%", color: labelColors[3]},
          {offset: "100%", color: labelColors[3]}
        ])
        .enter().append("stop")
        .attr("offset", function(d) { return d.offset; })
        .attr("stop-color", function(d) { return d.color; });

        const labelNames=['inconclusive','ER Lumen','ERM','ERM periphery','cytosolic'];
        
        //add localization tags to svg graphic(*except inconclusive)
        for (var i = 1; i <= 4; i++) {
          
          const labelContainer=svg.append('g');
          const testText=labelContainer
            .append("text")
            .attr('font-family','Arial')
            .attr('font-size',20)
            .text(labelNames[i]);
          const textbox=labelContainer.node().getBBox();
          labelContainer.attr("transform",`translate(${labelPositions[i].x},${labelPositions[i].y})`);
          labelContainer
            .append("rect")
            .attr("x",-3)
            .attr("y",-3)
            .attr("width",textbox.width+6)
            .attr("height",textbox.height+6)
            .attr("fill",labelColors[i])
            .attr("stroke","black")
            .attr("stroke-width",2);
          labelContainer
            .append("text")
            .attr('font-family','Arial')
            .attr('font-size',20)
            .attr("y",textbox.height-5)
            .text(labelNames[i]);
          testText.remove();
        }
        //add gene position in the submitochondria
        const positions = [{'x':240,'y':20},
        {'x':190,'y':200},
        {'x':190,'y':100},
        {'x':190,'y':80},
        {'x':280,'y':20}];
        console.log(LocalER);
        console.log(LocalER.substring(0, 6));
        //parse mitoaltas localization
        let sublocal=0;//0 equals inconclusive. 0 matrix 1 IMM 2 IMS 3 OMM-TM 4 OMM 5 nonmito
        if(LocalER==='Lumen'){
          sublocal=1
        } else if(LocalER==='ERM'){
          sublocal=2
        } else if(LocalER==='ERM peripheral (Cytosolic)'){
          sublocal=3
        } else {
          sublocal=4
        }
        const geneContainer=svg.append('g');
          const testText=geneContainer
            .append("text")
            .attr('font-family','Arial')
            .attr('font-size',20)
            .text(genename);
        const textbox=geneContainer.node().getBBox();
        geneContainer.attr("transform",`translate(${positions[sublocal].x},${positions[sublocal].y})`);
        geneContainer.append("rect")
          .attr('class', 'genename-label')
          .attr('x',-5)
          .attr('y',-3)
          .attr('rx',5)
          .attr('ry',5)
          .attr('width',textbox.width+10)
          .attr('height',textbox.height+6)
          .attr('stroke-width',2)
          .attr('stroke', 'black')
          .attr('fill', labelColors[sublocal]);
          geneContainer.append("text")
          .attr('stroke','black')
          .attr('x',0)
          .attr('y',textbox.height-5)
          .attr('font-family','Arial')
          .attr('font-size',20)
          .attr('class', 'genename-label')
          .text(genename); 
          testText.remove();

          const filename=`mitoatlas_sublocalization_of_${genename}`

          const saveOptions = [
            { format: "svg", label: "Save as SVG" },
            { format: "jpeg", label: "Save as JPEG" },
            { format: "png", label: "Save as PNG" },
            { format: "pdf", label: "Save as PDF" }
          ];
      
          saveButton(btncontainerRef, saveOptions, saveRef, 
            400,
            250,
             filename);

        }, [genename,LocalER]);

          return (
          <div>
          <svg width="400" height="250" ref={saveRef}>
            <svg id="preMadeSvgViewBox" viewBox="80 40 400 250" width="400" height="250" >
            <ERSVG/>
          </svg>
            <svg  ref={svgRef}>
          </svg>
          </svg>
          <div ref={btncontainerRef}>
          </div>
          </div>);
      }
