/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import './ProteinDetails.css';
import GeneralProperties from './GeneralProperties';
import IBAQ from './iBAQ';
import ERHeatmap from './ERHeatmap'
import SeqExplorer from './SeqExplorer'
import ICn3D from './iCn3D'
import parseTM from './parseTM';
import PubMedSearch from './PubMedSearch';
import DynamicTitle from './DynamicTitle';
import ERGeneral from './ERGeneral'; 

function ERDetails() {
  const currentHost = window.location.hostname;
  const navigate = useNavigate();
  const [protein, setProtein] = useState([]);
  const [length, setLength] = useState(0);
  const [style, setStyle] = useState([]);
  const { uid } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('er');
  const [selectedDropdown, setSelectedDropdown] = useState('er_general');
  const source = axios.CancelToken.source();
  
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
    setSelectedDropdown('');
  };

  const handleDropdownClick = (dropdownName) => {
    setSelectedDropdown(dropdownName);
  }
  
  useEffect(() => {
    const debouncedQuery = debounce(() => {

    setLoading(true);
    const queries=[
      axios.get(`https://spatiomics.org/api/search?search=${uid}&filter=er`,),
      axios.get('https://spatiomics.org/api/style',),
    ];

    Promise.all(queries)
          .then(([response,response2]) => {
            setProtein(response.data);
            setLength(response.data.length);
            setStyle(response2.data);
            //console.log('using style');
            //console.log(response.data);
            //console.log(response2.data);
          })
          .catch(error => {
            console.error(`there was an error communicating to the API: ${error}`);
          })
          .finally(() => {
            setLoading(false);
          });
        }, 100);

        debouncedQuery();
        return () => {
          source.cancel();
        };
      }, [uid]);

        if (loading) {
        return <div>
            Loading...</div>;
        }
        else if (length>1){
            navigate('/searchresult', { state: { results: protein } });
        } else if (length==0){
            navigate('/searchresult', { state: { results: "notfound" } });
        }
        else  if (!protein) {
        return <div>
            Protein not found.</div>;
        } else {
        //console.log(`using following resource to draw this page`);
        console.log(protein);
        
        const tm_manual=protein[0].TMManual;
        const tm_uniprot=protein[0].TMUniProt;
        const tm_deeptmhmm=protein[0].DeepTMHMM;
        const tm_tmbed=protein[0].TMbed;
        const parsedTMManual=parseTM('Manual',tm_manual);
        const parsedTMUniprot=parseTM('UniProt',tm_uniprot);
        const parsedTMbed=parseTM('TMbed',tm_tmbed);
        const parsedDeepTMHMM=parseTM('DeepTMHMM',tm_deeptmhmm);
        console.log('parsedDeepTMHMM');
        console.log(parsedDeepTMHMM);
        const domain = protein[0].InterPro;
        return (
            <>
            <div className="App">
        <div className="App-header">
          <DynamicTitle />
        </div>

        </div>
        
        <SearchBar />
        
        <div className="title">
            {protein[0].GeneName}
        </div>
        <div className="protein-details">
        <div className="tabs">
        
        <button
          className={selectedTab === 'er' ? 'active' : 'inactive'}
          onClick={() => {
            handleTabClick('er');
            handleDropdownClick('er_general');
          }}
        >
          ER
        </button>
        
        <div className={selectedTab === 'er' ? 'dropdown-container show' : 'dropdown-container hide'}>
          <div className="dropdown">
            <button onClick={() => handleDropdownClick('er_general')}>Summary</button>

            {/* 
            
            <button onClick={() => handleDropdownClick('erseq_explorer')}>Sequence explorer</button>

            */}

            <button onClick={() => handleDropdownClick('er_apex2_heatmap')}>APEX2 Intensity Heatmap</button>
            <button onClick={() => handleDropdownClick('er_turboid_heatmap')}>TurboID Intensity Heatmap</button>
            <button onClick={() => handleDropdownClick('er_icn3d')}>iCn3D viewer</button>
             {/* 
             
             <button onClick={() => handleDropdownClick('er_pubmed')}>Eutils API PubMed search</button>
             
             */}
          </div>
        </div>

        <button
          className={selectedTab === 'expression' ? 'active' : 'inactive'}
          onClick={() => handleTabClick('expression')}
        >
          Expression
        </button>
      </div>

      {selectedTab === 'expression' && (
        <div className="content expression">
          <IBAQ uniprotID={protein[0].UniProtID} genename={protein[0].GeneName} />
        </div>
      )}

      {selectedTab === 'er' && selectedDropdown === 'er_general' &&(
        <div className="content er-general">
        <ERGeneral 
        style={style}
        uniprotID={protein[0].UniProtID} 
        ProteinName={protein[0].ProteinName}
        genename={protein[0].GeneName} 
        LocalER={protein[0].LocalER}
        LocalUniProt={protein[0].LocalUniProt}
        tm_manual={parsedTMManual}
        tm_uniprot={parsedTMUniprot}
        tm_deeptmhmm={parsedDeepTMHMM}
        tm_tmbed={parsedTMbed}
        sequence={protein[0].Sequence}
        domain={domain} 
        />
      </div>
      )}

      {selectedTab === 'er' && selectedDropdown === 'er_apex2_heatmap' &&(
        <div className="content er-heatmap">
        <ERHeatmap style={style}
        study='er' enzyme='APEX2' uniprotID={protein[0].UniProtID} genename={protein[0].GeneName} />
      </div>
      )}
      {selectedTab === 'er' && selectedDropdown === 'er_turboid_heatmap' &&(
        <div className="content er-heatmap">
        <ERHeatmap style={style}
        study='er' enzyme='TurboID' uniprotID={protein[0].UniProtID} genename={protein[0].GeneName} />
      </div>
      )}

      </div>
        </>
        );
        }
    }

export default ERDetails;
