import React, { useState, useEffect, useRef, useCallback} from 'react';
import * as d3 from 'd3';
import getcolor from './getcolor';
import saveButton from './saveButton';
import './TopologySVG.css';
import ERevalTManno from './ERevalTManno';

export default function ERTopologySVG({genename,
        sites,
        style,
        tm_manual,
        tm_uniprot,
        tm_deeptmhmm,
        tm_tmbed,
        sequence,
        LocalER}){

    const [isOn, setIsOn] = useState(false);

    function handleToggle() {
        setIsOn(!isOn);
    }

    const svgRef=useRef(null);

    const svgcontainerRef=useRef(null);

    const btncontainerRef=useRef(null);

    const [selectedTM, setSelectedTM] = useState(""); // keep track of selected TM source

    const [tmOptions, setTMOptions] = useState([]);

    const [isoption, setIsoption] = useState(false);

    useEffect(()=>{
        const options = [];
        if (tm_manual.length>0) {
            const scores = ERevalTManno({sites:sites, TMs:tm_manual});
            console.log(scores);
            options.push({ value: "manual", 
                label: `TM from Manual annotation, ${scores.targetMem} , score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
                score1: scores.score1, 
                score2: scores.score2, 
                maxscore: Math.max(scores.score1, scores.score2)});
        }
        if (tm_uniprot.length>0) {
            const scores = ERevalTManno({sites:sites, TMs:tm_uniprot});
            console.log(scores);
            options.push({ value: "uniprot", 
                label: `TM from UniProt, ${scores.targetMem} , score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
                score1: scores.score1, 
                score2: scores.score2, 
                maxscore: Math.max(scores.score1, scores.score2)});
            //setSelectedTM('uniprot'); // set selectedTM to 'uniprot' if parsedTMuniprot is not empty
        }
        if (tm_deeptmhmm.length>0) {
            const scores = ERevalTManno({sites:sites,TMs:tm_deeptmhmm});
            options.push({ value: "deeptmhmm",
                label: `TM from DeepTMHMM, ${scores.targetMem} , score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
                score1: scores.score1, 
                score2: scores.score2,
                maxscore: Math.max(scores.score1, scores.score2)});
        }
        if (tm_tmbed.length>0) {
            const scores = ERevalTManno({sites:sites,TMs:tm_tmbed});
            options.push({ value: "tmbed", 
                label: `TM from TMbed, ${scores.targetMem} , score ${Math.round(Math.max(scores.score1, scores.score2)*100)/100}`, 
                score1: scores.score1, 
                score2: scores.score2,
                maxscore: Math.max(scores.score1, scores.score2)});
        }

        if( selectedTM === '' && options.length > 0 ){
        //draw the topology of the highest scoring protein with the desired direction
        const optionWithHighestScore = options.reduce((acc, cur) => {
            return cur.maxscore > acc.maxscore ? cur : acc;
            });
            
        if( optionWithHighestScore.score2 > optionWithHighestScore.score1 ){

            setIsOn(true);

        }

        setSelectedTM(optionWithHighestScore.value);
        
        setIsoption(true);
        
        }
        // set the TM options when the component mounts
        const updatedOptions = options.slice(); // create a copy of the options array

        setTMOptions(updatedOptions);

    },[ genename, selectedTM, sites, tm_manual, tm_deeptmhmm, tm_tmbed, tm_uniprot ]);

    const handleTMChange = useCallback((event) => {

        setSelectedTM(event.target.value);

        console.log(tmOptions);

        if(tmOptions.length > 0){

            const selected = tmOptions.find((d) => d.value === event.target.value);

            console.log(selected);

            if( selected.score2 > selected.score1 ){


                setIsOn(true);

            } else if ( selected.score2 < selected.score1 ) {


                setIsOn(false);
            
            };

        }
        
      }, [ tmOptions ]);

    useEffect(()=>{

        const labelNames=['inconclusive','Lumen','ERM','Cytosol'];

        if(tmOptions.length<1) return;

        let usedTM = [] ;
        let tmScore = 0 ;
        if(selectedTM==='manual'){
            usedTM = tm_manual;
            if(!isOn){
                tmScore = tmOptions.find((d) => d.value === 'manual').score1 ;
            } else {
                tmScore = tmOptions.find((d) => d.value === 'manual').score2 ;
            }
            

        } else if(selectedTM==='uniprot'){
            usedTM = tm_uniprot;
            if(!isOn){
                tmScore = tmOptions.find((d) => d.value === 'uniprot').score1 ;
            } else {
                tmScore = tmOptions.find((d) => d.value === 'uniprot').score2 ;
            }
            

        } else if(selectedTM==='deeptmhmm'){

            usedTM = tm_deeptmhmm;

            if(!isOn){
                tmScore = tmOptions.find((d) => d.value === 'deeptmhmm').score1 ;
            } else {
                tmScore = tmOptions.find((d) => d.value === 'deeptmhmm').score2 ;
            }

        } else if(selectedTM==='tmbed'){

            usedTM = tm_tmbed;

            if(!isOn){
                tmScore = tmOptions.find((d) => d.value === 'tmbed').score1 ;
            } else {
                tmScore = tmOptions.find((d) => d.value === 'tmbed').score2 ;
            }
        }

        let sublocal=0;//0 equals inconclusive. 0 matrix 1 IMM 2 IMS 3 OMM-TM 4 OMM 5 nonmito
        
        if( LocalER === 'Lumen'){

          sublocal = 1

        } else if (LocalER === 'ERM'){

          sublocal = 2

        } else if(LocalER === 'ERM peripheral (Cytosolic)'){

          sublocal =3 

        } else if(LocalER === 'Cytosolic'){

          sublocal=4
       
        } else {

          sublocal = 0
        
        }
        const labelColors = [getcolor(style,'inconclusive','lighthex'),
        getcolor(style,'Lumen','lighthex'),
        "url(#immGradient)",
        getcolor(style,'ERM peripheral (Cytosolic)','lighthex'),
        "url(#ommGradient)",
        getcolor(style,'Cytosolic','lighthex'),
        getcolor(style,'tm-odd','lighthex'),
        getcolor(style,'tm-even','lighthex')];

        const svg = d3.select(svgRef.current);
        svg.selectAll('*').remove();
        
        const memHeight=50;
        
        const container=svg.append('g');

        let upperBox=container.append("rect");

        let lowerBox=container.append("rect");

        const membrane=container.append("g");

        const membraneRect=membrane.append("rect")
            .attr("x",-10)
            .attr("height",memHeight)
            //.attr("y",height*0.4)
            .attr("fill","#dbd809")
            .attr("stroke","black")
            .attr("stroke-width",5);
            
        //draw local tags
        let upperTag=container.append("g");

        let lowerTag=container.append("g");

        let memTag=container.append("g");

        let upperColor=labelColors[0];

        let upperText='';

        let lowerColor=labelColors[0];

        let lowerText=''

        let memText=''

        if(sublocal === 2){

            upperColor = labelColors[3];

            upperText = labelNames[3];

            lowerColor = labelColors[1];

            lowerText = labelNames[1];

            memText = labelNames[2];
            
        }
        if(sublocal===4){
            upperColor=labelColors[5];
            upperText=labelNames[5];
            lowerColor=labelColors[3];
            lowerText=labelNames[3];
            memText=labelNames[4];
        }
        upperBox
            .attr("fill",upperColor);
        const upperTagRect = upperTag.append("rect")
            .attr("fill",upperColor);
        const upperTagText = upperTag.append("text").text(upperText)
            .attr("font-size",20)
            .attr("font-family","Arial");  
        lowerBox
            .attr("fill",lowerColor);
        const lowerTagRect = lowerTag.append("rect")
            .attr("fill",upperColor);
        const lowerTagText = lowerTag.append("text").text(lowerText)
            .attr("font-size",20)
            .attr("font-family","Arial");  
        
        const memTagText = memTag.append("text").text(memText)
            .attr("font-size",20)
            .attr("font-family","Arial");  
        
        //draw TMs
        const tmsContainer=membrane.append("g");

        let prevXpos=0;

        let prevAnchor={x:0,y:0,i:0};

        let prevTMdirection=-1;

        if( isOn ){
            prevTMdirection = -prevTMdirection;
        }
        
        let maxHeight = 300;

        let maxdeltaY1 = memHeight*(7/16);
        let maxdeltaY2 = memHeight*(7/16);
        let calcHeight = 0;
        let calcWidth = 0;

        usedTM.forEach((tm,i) => {

            const tmContainer = tmsContainer.append("g").attr("transform",`translate(${prevXpos},0)`)
            
            const tmRect = tmContainer.append("rect")
                .attr("height", memHeight*(15/8))
                .attr("fill", labelColors[6+i%2])
                .attr("stroke","black")
                .attr("stroke-width",5);
                
            const startText = tmContainer.append("text")
                .attr('font-family','Arial')
                .attr('font-size',20)
                .attr("text-anchor", "middle")


            if (prevTMdirection === 1) {

                startText.text(tm.start);

            } else {

                startText.text(tm.end);

            }

            const textBox = startText.node().getBBox();

            const textHeight = textBox.height;

            startText.attr('y',textHeight);

            const endText = tmContainer.append("text")
                .attr('font-family','Arial')
                .attr('font-size',20)
                .attr("text-anchor", "middle")
                .attr('y',memHeight*(15/8)-8);

            if (prevTMdirection === 1) {

                endText.text(tm.end);

            } else {

                endText.text(tm.start);

            }

            const tmBox = tmContainer.node().getBBox();

            tmRect.attr( "width" , tmBox.width + 10).attr( "x" , 0 );
            startText.attr( "x" , tmBox.width/2 + 5);
            endText.attr( "x" , tmBox.width/2 + 5 ) ;
            
            //add labled sites as rects and text to TM domains
            
            const targetSites = sites.filter(d => d.site <= tm.end && d.site >= tm.start);
            const calcY1 = memHeight*(15/16)*( 1-  prevTMdirection );
            const calcY2 = memHeight*(15/16)*( 1 + prevTMdirection );

            targetSites.forEach((d,i)=>{

                const siteTextContainer = tmsContainer.append("g").attr("class","labeledsite").attr("x",0).attr("y",0).attr("transform",`translate(${prevXpos},0)`);
                
                const siteRect=siteTextContainer.append("rect");

                const siteText=siteTextContainer.append("text")

                let AAtext=''

                if(d.enzyme==="APEX2"){

                    AAtext='Y'

                } else if (d.enzyme==="BioID" || d.enzyme==="TurboID"){

                    AAtext='K'

                }
                
                siteText.text(`${AAtext}${d.site}`)
                   .attr("font-size",20)
                   .attr("font-family","Arial");
               
                const siteTextBox = siteTextContainer.node().getBBox();

                let siteColor = '#ffffff'

                if (d.LocalER === 0){

                    siteColor = labelColors[0];

                } else if (d.LocalER === 1){

                    siteColor = siteColor = getcolor(style,'Lumen','hex');

                } else if (d.LocalER === 5){

                    siteColor = siteColor = getcolor(style,'Cytosolic','hex');

                }

                siteRect
                   .attr("width", siteTextBox.width + 10)
                   .attr("height", siteTextBox.height + 10)
                   .attr("x",-5)
                   .attr("y",calcY1-10+0.5*(siteTextBox.height+10)*(1+prevTMdirection)+(calcY2-calcY1-1*(siteTextBox.height+10)*(1*prevTMdirection))*((d.site-tm.start)/(tm.end-tm.start)*1)-siteTextBox.height)
                   .attr("rx",20)
                   .attr("stroke","black")
                   .attr("stroke-width",5)
                   .attr("fill", siteColor);
                
                siteText.attr("y", calcY1-10+0.5*(siteTextBox.height+10)*(1+prevTMdirection)+(calcY2-calcY1-1*(siteTextBox.height+10)*(1*prevTMdirection))*((d.site-tm.start)/(tm.end-tm.start)*1));
     

           });            
            // Define the curve function
            const curve = d3.curveBasis;

            // Define the line function
            const line = d3.line()
            .x(d => d.x)
            .y(d => d.y)
            .curve(curve);
        
            //if first TM, draw the N terminus.if length is larger than 50, make it longer as it scales.
           
            if(i === 0){
                // Define the data points for the curve
                const calcY1=memHeight*(15/16)*(1-prevTMdirection);
                const calcY2=memHeight*(15/16)*(1-prevTMdirection)-Math.min(maxHeight,(Math.max(10,(tm.start-1)*2-40)))*prevTMdirection;
                const calcY3=memHeight*(15/16)*(1-prevTMdirection)-(Math.min(maxHeight,(Math.max(10,(tm.start-1)*2-40)))+50)*prevTMdirection;
                const calcX1=tmBox.width/2+5;
                const calcX2=calcX1-50;
                const data = [
                    {x: calcX2, y: calcY3},
                    {x: calcX1+(calcX2-calcX1)*(1-0.5), y: calcY3},
                    {x: calcX1+(calcX2-calcX1)*(1-1/1.25), y: calcY2+(calcY3-calcY2)/1.25},
                    {x: calcX1, y: calcY2+(calcY3-calcY2)*0.5},
                    {x: calcX1, y: calcY2},
                    {x: calcX1, y: calcY1*0.33+calcY2*0.66},
                    {x: calcX1, y: calcY1*0.66+calcY2*0.33},
                    {x: calcX1, y: calcY1}
                ];
                // Append a path element to the SVG
                const pathContainer = tmsContainer.append("g").attr("transform",`translate(${prevXpos},0)`);

                const path = pathContainer
                    .append("path")
                    .datum(data)
                    .attr("fill", "none")
                    .attr("stroke-width", 18)
                    .attr("d", line)
                    .attr("stroke","black");

                const pathText=pathContainer.append("text")
                    .text("N-term:1")
                    .attr("font-size",20)
                    .attr("font-family","Arial");
                const pathTextBox=pathText.node().getBBox();

                pathText.attr("y",calcY3+pathTextBox.height*0.25)
                    .attr("text-anchor","end")
                    .attr("x",-pathTextBox.width/2-5);
                
                // toss the prevAnchor position to next TM
                prevAnchor={x:-tmBox.width/2-25, y:memHeight*(15/16)*(1+prevTMdirection),i:tm.end+1};
                
                // create a copy of the path element and set its stroke properties
                const outline = path.clone(true)
                    .attr("stroke-width", 8)
                    .attr("opacity", 0.8);
                // append the outline element to the SVG
                path.node().parentNode.appendChild(outline.node());
                //add N-terminal labled sites as rects and text
                const targetSites = sites.filter(d => d.site < tm.start);

                targetSites.forEach((d,i)=>{
                    const siteTextContainer = tmsContainer.append("g").attr("class","labeledsite").attr("x",0).attr("transform",`translate(${prevXpos},0)`)
                    .attr("y",0);
                    const siteRect=siteTextContainer.append("rect");
                    const siteText=siteTextContainer.append("text");
                    let AAtext='?'
                    if(d.enzyme === "APEX2"){
                        AAtext='Y'
                    } else if (d.enzyme === "BioID" || d.enzyme === "TurboID"){
                        AAtext='K'
                    }
                    siteText.text(`${AAtext}${d.site}`)
                        .attr("font-size",20)
                        .attr("font-family","Arial");

                    const siteTextBox=siteText.node().getBBox();

                    siteText.attr("y",calcY1+(calcY3-calcY1)*((tm.start-d.site)/tm.start*0.9+0.05))
                        .attr("x",0);

                    let siteColor = '#ffffff'

                    if (d.LocalER === 0){
    
                        siteColor = labelColors[0];
    
                    } else if (d.LocalER === 1){
    
                        siteColor = getcolor(style,'Lumen','hex');
    
                    } else if (d.LocalER === 5){
    
                        siteColor = getcolor(style,'Cytosolic','hex');
    
                    }

                    siteRect
                        .attr("x", -5)
                        .attr("y", calcY1+(calcY3-calcY1)*((tm.start-d.site)/tm.start*0.9+0.05)-siteTextBox.height)
                        .attr("width", siteTextBox.width+10)
                        .attr("height", siteTextBox.height+10)
                        .attr("rx", 20)
                        .attr("stroke", "black")
                        .attr("stroke-width", 5)
                        .attr("fill", siteColor);
                });
                 
                //keep track of delta to draw upperbox and lowerbox height
                const pathBox=pathContainer.node().getBBox();

                if(prevTMdirection === 1){
                    outline.attr("stroke",upperColor);
                    maxdeltaY1=Math.max(maxdeltaY1,pathBox.height+memHeight*(7/16));
                } else {
                    outline.attr("stroke",lowerColor);
                    maxdeltaY2=Math.max(maxdeltaY2,pathBox.height+memHeight*(7/16));
                }
           } else {
            //if not first TM, draw the path connecting prev anchor and current anchor

            // Define the data points for the curve
            const calcY1=memHeight*(15/16)*(1-prevTMdirection);
            const calcY2=memHeight*(15/16)*(1-prevTMdirection)-Math.min(maxHeight,Math.max(50,(tm.start-prevAnchor.i)))*prevTMdirection;
            const calcX1=tmBox.width/2+5;
            const data = [
                {x: prevAnchor.x, y: prevAnchor.y},
                {x: prevAnchor.x, y: calcY1*0.66+calcY2*0.33},
                {x: prevAnchor.x, y: calcY1*0.33+calcY2*0.66},
                {x: prevAnchor.x, y: calcY2},
                {x: prevAnchor.x+(calcX1-prevAnchor.x)/2*(1-1/1.414), y: calcY2-20*prevTMdirection/1.414},
                {x: (prevAnchor.x+calcX1)/2, y: calcY2-20*prevTMdirection},
                {x: calcX1-(calcX1-prevAnchor.x)/2*(1-1/1.414), y: calcY2-20*prevTMdirection/1.414},
                {x: calcX1, y: calcY2},
                {x: calcX1, y: calcY1*0.33+calcY2*0.66},
                {x: calcX1, y: calcY1*0.66+calcY2*0.33},
                {x: calcX1, y: calcY1}
            ];
            // Append a path element to the SVG
            const pathContainer = tmsContainer.append("g").attr("transform",`translate(${prevXpos},0)`);

            const path = pathContainer
                .append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", "black")
                .attr("stroke-width", 18)
                .attr("d", line)
                .style("position","relative");
            
            // create a copy of the path element and set its stroke properties
            const outline = path.clone(true)
            .attr("stroke-width", 8)
            .attr("opacity", 0.8)
            .style("position","relative");
            // append the outline element to the SVG
            path.node().parentNode.appendChild(outline.node());

           //add labled sites on the path as rects and text
           //left half and right half portion should be rendered differently for correct visualization
           const targetSites = sites.filter(d => d.site < tm.start && d.site >= prevAnchor.i);
           targetSites.forEach((d,i)=>{
               const siteTextContainer = tmsContainer.append("g").attr("class","labeledsite").attr("x",0).attr("transform",`translate(${prevXpos},0)`)
               .attr("y",0);
               const siteRect=siteTextContainer.append("rect");
               const siteText=siteTextContainer.append("text");
               let calcSiteY=0;
               let calcSiteX=0;
               //relSitePos is the abstract position of where in the loop the site is from 0 to 1
               const relSitePos = ((tm.start-1)-d.site)/((tm.start-1)-prevAnchor.i);

               const minSiteOffset = - 20 ; 

               const maxSiteOffset = 20 ; 

               if(relSitePos === 0.5){
                    calcSiteX = ((prevAnchor.x+calcX1)/2);
                    calcSiteY = calcY2;
               } else if( relSitePos > 0.5 ) {
                    calcSiteX = prevAnchor.x ;
                    calcSiteY = calcY1 + minSiteOffset * prevTMdirection + ( calcY2 + maxSiteOffset * prevTMdirection - calcY1 - minSiteOffset * prevTMdirection ) * ( 0.5 -relSitePos ) / 0.5 ;
               } else {
                    calcSiteX = calcX1 ;
                    calcSiteY = calcY1 + minSiteOffset * prevTMdirection + ( calcY2 + maxSiteOffset * prevTMdirection - calcY1 - minSiteOffset * prevTMdirection ) * ( relSitePos ) / 0.5 ;
               }

               let AAtext='?'
               
               if(d.enzyme === "APEX2"){
                   AAtext='Y'
               } else if (d.enzyme === "BioID" || d.enzyme === "TurboID"){
                   AAtext='K'
               }
               
               siteText.text(`${AAtext}${d.site}`)
                   .attr("font-size",20)
                   .attr("font-family","Arial")
                   .attr("text-anchor","middle");
               const siteTextBox=siteText.node().getBBox();
               siteText.attr("y",calcSiteY+siteTextBox.height*0.5*(1-prevTMdirection)).attr("x",calcSiteX);

               let siteColor = '#ffffff';

               if (d.LocalER === 0){
    
                siteColor = labelColors[0];

                } else if (d.LocalER === 1){

                    siteColor = getcolor(style,'Lumen','hex');

                } else if (d.LocalER === 5){

                    siteColor = getcolor(style,'Cytosolic','hex');

                }

               siteRect
                   .attr("x",calcSiteX-siteTextBox.width/2-5)
                   .attr("y",calcSiteY-siteTextBox.height+siteTextBox.height*0.5*(1-prevTMdirection))
                   .attr("width",siteTextBox.width+10)
                   .attr("height",siteTextBox.height+10)
                   .attr("rx",20)
                   .attr("stroke","black")
                   .attr("stroke-width",5)
                   .attr("fill", siteColor);
           });

            // toss the prevAnchor position to next TM
                prevAnchor={x:-tmBox.width/2-25, y:memHeight*(15/16)*(1+prevTMdirection),i:tm.end+1};

            //keep track of delta to draw upperbox and lowerbox height
            const pathBox=pathContainer.node().getBBox();

            if(prevTMdirection === 1){
                outline.attr("stroke",upperColor);
                maxdeltaY1=Math.max(maxdeltaY1,pathBox.height+memHeight*(7/16));
            } else {
                outline.attr("stroke",lowerColor);
                maxdeltaY2=Math.max(maxdeltaY2,pathBox.height+memHeight*(7/16));
            }

           }

           //if last TM, draw the C terminus.if length is larger than 50, make it longer as it scales.
           
           if(i === usedTM.length-1){
            // Define the data points for the curve
            const calcX1 = tmBox.width/2+5;
            const calcX2 = calcX1+50;
            const calcY1 = memHeight*(15/16)*(1+prevTMdirection);
            const calcY2 = memHeight*(15/16)*(1+prevTMdirection)+Math.min(maxHeight,(Math.max(10,(sequence.length-tm.end)*2-40)))*prevTMdirection;
            const calcY3 = memHeight*(15/16)*(1+prevTMdirection)+(Math.min(maxHeight,(Math.max(10,(sequence.length-tm.end)*2-40)))+50)*prevTMdirection;

            const data = [
                {x: calcX2, y: calcY3},
                {x: calcX1+(calcX2-calcX1)*(1-0.5), y: calcY3},
                {x: calcX1+(calcX2-calcX1)*(1-1/1.25), y: calcY2+(calcY3-calcY2)/1.25},
                {x: calcX1, y: calcY2+(calcY3-calcY2)*0.5},
                {x: calcX1, y: calcY1*0.33+calcY2*0.66},
                {x: calcX1, y: calcY1*0.66+calcY2*0.33},
                {x: calcX1, y: calcY1}
            ];
            // Append a path element to the SVG
            const pathContainer = tmsContainer.append("g").attr("transform",`translate(${prevXpos},0)`);
            const path = pathContainer
                .append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", "black")
                .attr("stroke-width", 18)
                .attr("d", line);
            const pathText=pathContainer.append("text")
                .text(`C-term:${sequence.length}`)
                .attr("font-size",20)
                .attr("font-family","Arial");
            const pathTextBox=pathText.node().getBBox();

            pathText.attr("y",calcY3+pathTextBox.height*0.25)
                .attr("text-anchor","start")
                .attr("x",tmBox.width/2+10+50);
            // toss the prevAnchor position to next TM
            prevAnchor={x:tmBox.width/2+5, y:memHeight*(15/16),i:tm.end+1};

            // create a copy of the path element and set its stroke properties
            const outline = path.clone(true)
                .attr("stroke-width", 8)
                .attr("opacity", 0.8)
                .style("position","relative");
            // append the outline element to the SVG
            path.node().parentNode.appendChild(outline.node());

            //add C-terminal labled sites as rects and text
            const targetSites = sites.filter(d => d.site > tm.end);
            
            targetSites.forEach((d,i)=>{

                const siteTextContainer = tmsContainer.append("g")
                    .attr("class","labeledsite")
                    .attr("x",0)
                    .attr("y",0);

                const siteRect = siteTextContainer.append("rect");

                const siteText = siteTextContainer.append("text");
                
                let AAtext='?'
                if(d.enzyme === "APEX2"){
                    AAtext='Y'
                } else if (d.enzyme === "BioID" || d.enzyme === "TurboID"){
                    AAtext='K'
                }
                
                siteText.text(`${AAtext}${d.site}`)
                    .attr("font-size",20)
                    .attr("font-family","Arial")
                    .attr('text-anchor','start');

                const siteTextBox = siteText.node().getBBox();

                siteTextContainer.attr("transform",`translate(${prevXpos+(tmBox.width + 10)/2-(siteTextBox.width+10)/2},0)`);

                siteText
                    .attr("y",2+calcY1+(calcY3-calcY1)*((d.site-tm.end)/(sequence.length-tm.end)*0.9+0.05))
                    .attr("x",5);
                
                let siteColor = '#ffffff'

                if (d.LocalER === 0){

                    siteColor = labelColors[0];

                } else if (d.LocalER === 1){

                    siteColor = siteColor = getcolor(style,'Lumen','hex');

                } else if (d.LocalER === 5){

                    siteColor = siteColor = getcolor(style,'Cytosolic','hex');

                }

                siteRect
                    .attr("x", 0 )
                    .attr("y", calcY1+(calcY3-calcY1)*((d.site-tm.end)/(sequence.length-tm.end)*0.9+0.05)-siteTextBox.height)
                    .attr("width",siteTextBox.width+10)
                    .attr("height",siteTextBox.height+10)
                    .attr("rx",20)
                    .attr("stroke","black")
                    .attr("stroke-width",5)
                    .attr("fill", siteColor);
            });

            //keep track of delta to draw upperbox and lowerbox height
            const pathBox=pathContainer.node().getBBox();
            if(prevTMdirection !== 1){
                outline.attr("stroke",upperColor);
                maxdeltaY1=Math.max(maxdeltaY1,pathBox.height+memHeight*(7/16));
            } else {
                outline.attr("stroke",lowerColor);
                maxdeltaY2=Math.max(maxdeltaY2,pathBox.height+memHeight*(7/16));
            }
       }

            //update prevXpos
            prevXpos += tmBox.width + 10 + 20 ;
            prevTMdirection=-prevTMdirection;
        });
        const margin = { top : 150 , bottom : 50 , left : 70 , right : 70 } ;
        if(tmsContainer.node()){
            const tmsBox=tmsContainer.node().getBBox();

            calcWidth= tmsBox.width + margin.left + margin.right ;

            calcHeight= maxdeltaY1 + memHeight + maxdeltaY2 + margin.top + margin.bottom;
            
            svg
                .attr("width",calcWidth)
                .attr("height",calcHeight);

            tmsContainer.attr( "transform" , `translate(${ -tmsBox.x + margin.left } , ${ -tmsBox.y + margin.top})`);

            upperBox
                .attr("y",0)
                .attr("width", calcWidth )
                .attr("height", maxdeltaY1 + margin.top );

            lowerBox
                .attr("y", calcHeight - maxdeltaY2 - margin.bottom )
                .attr("width", calcWidth )
                .attr("height", maxdeltaY2 + margin.bottom ) ;
            
            membraneRect
                .attr("width", calcWidth + 20 )
                .attr("y", maxdeltaY1 + margin.top  );

            const upperTextBox = upperTagText.node().getBBox();

            upperTagRect.attr('width', upperTextBox.width )
                .attr( "height", upperTextBox.height + 5 )
                .attr( 'y', -upperTextBox.height )
                .attr( 'fill', "white" )
                .attr( 'opacity' , 0.5 );

            upperTag.attr("transform",`translate(0,${ maxdeltaY1 + margin.top - 20 })`);

            const lowerTextBox = lowerTagText.node().getBBox();

            lowerTagRect.attr( 'width' , lowerTextBox.width )
                .attr( "height" , lowerTextBox.height + 5 )
                .attr( 'y' , -lowerTextBox.height )
                .attr( 'fill' , "white" )
                .attr( 'opacity' , 0.5 );
                lowerTag.attr("transform",`translate(0,${ maxdeltaY1 + memHeight + lowerTextBox.height + margin.top + 10 })`);

            const memTextBox=memTagText.node().getBBox();
                memTag.attr("transform",`translate(0,${maxdeltaY1 + memHeight/2 + memTextBox.height/2 + margin.top - 5 })`);

            container.append("text")
                .text(`Membrane Topology of ${genename}`)
                .attr("font-size", 20 )
                .attr("font-family", "arial")
                .attr("y", 25)
                .attr("x", 10);
            
            container.append("text")
                .text(`TM annotation from ${selectedTM}`)
                .attr("font-size", 20 )
                .attr("font-family", "arial")
                .attr("y", 55)
                .attr("x", 10);
            
            container.append("text")
                .text(`Topology Score : ${Math.round(tmScore*100)/100}`)
                .attr("font-size", 20 )
                .attr("font-family", "arial")
                .attr("y", 85)
                .attr("x", 10);
            //forcesimulation setup for force directed graph
            const rects = container.selectAll('.labeledsite');

            let siteData = [];

            let rectsArray ={x:[],x2:[],x3:[],y:[],y2:[],y3:[]};         
            // loop through the nodes array and log the X position of each group
            // parse group transfomation data and child rect y transform data . x is not always -5;
            rects.each(function(d) {

                var x = parseFloat(d3.select(this).attr("transform").split("(")[1].split(",")[0]);
                var y = parseFloat(d3.select(this).attr("transform").split(",")[1].split(")")[0]);

                const rectX = d3.select(this).selectAll('rect').nodes().map(function(rect){return +rect.getAttribute('x')})[0];
                const rectY = d3.select(this).selectAll('rect').nodes().map(function(rect){return +rect.getAttribute('y')})[0];

                rectsArray.x.push(x);
                rectsArray.x2.push(rectX);
                rectsArray.x3.push(d3.select(this).selectAll('rect').nodes().map(function(rect){return +rect.getAttribute('width')})[0]);

                rectsArray.y.push(y);
                rectsArray.y2.push(rectY);
                rectsArray.y3.push(d3.select(this).selectAll('rect').nodes().map(function(rect){return +rect.getAttribute('height')})[0]);

            })

            rects.nodes().forEach((d, i) => {

                const siteBbox = d.getBBox();

                let dataObject = {
                    x: rectsArray.x[i]+rectsArray.x2[i],
                    y: rectsArray.y[i]+rectsArray.y2[i],
                    startX: rectsArray.x[i]+rectsArray.x2[i]+rectsArray.x3[i]/2,
                    startY: rectsArray.y[i]+rectsArray.y2[i]+siteBbox.height/2,
                    width: siteBbox.width,
                    height: siteBbox.height,
                    element: d
                };

                siteData.push(dataObject);
                });

            function ticked() {
                //simulation onyl updates the translate of group but the actual object we want to move is the rect 
                //so we have to compensate for that difference
                rects
                  .data(siteData)
                  .attr('transform', function(d,i) {
                    return 'translate(' +(d.x-rectsArray.x2[i])+ ','+ (d.y-rectsArray.y2[i])+ ')';
                  });

                lines
                  .data(siteData)
                  .attr('x1', function(d,i) {
                    //return (d.x+d.width/2)})
                    return (d.startX + 0 * Math.sign( d.x ))})
                    .attr('y1', function(d,i) {
                        return (d.startY)})
                  .attr('x2', function(d,i) {

                    if ( Math.abs(d.startY-d.y-d.height/2) < Math.abs(d.startX-d.x-d.width/2) ){

                        return (d.x-Math.sign(d.x+d.width/2)*Math.abs(d.y+d.height/2-d.startY)+d.width/2)

                    } else {

                        return (d.x+d.width/2)

                    }
                    })
                  .attr('y2', function(d,i) {

                    if ( Math.abs(d.startY-d.y-d.height/2) < Math.abs(d.startX-d.x-d.width/2) ){
                        
                        return (d.startY)
                        
                    } else {

                        return ( d.y + d.height/2 )

                    }

                    });
                    //return (d.y+d.height/2)});

                lines2
                    .data(siteData)
                    .attr('x1', function(d,i) {

                        if ( Math.abs(d.startY-d.y-d.height/2) < Math.abs(d.startX-d.x-d.width/2) ){

                        return (d.x-Math.sign(d.x+d.width/2)*Math.abs(d.y+d.height/2-d.startY)+d.width/2);

                        } else {

                            return (d.x+d.width/2);

                        }
                    
                    })
                    .attr('y1', function(d,i) {

                        if ( Math.abs(d.startY-d.y-d.height/2) < Math.abs(d.startX-d.x-d.width/2) ){
                            
                            return ( d.startY );
                        
                        } else {

                            return ( d.y + d.height/2 ); 

                        }

                        })
                        //return (d.y+d.height/2)});

                    .attr('x2', function(d,i) {

                      return (d.x+d.width/2)})

                    .attr('y2', function(d,i) {
                        
                      return (d.y+d.height/2)});

                linesThin
                    .attr('x2', function(d,i) {

                        if ( Math.abs(d.startY-d.y-d.height/2) < Math.abs(d.startX-d.x-d.width/2) ){
                            
                            return (d.x-Math.sign(d.x+d.width/2)*Math.abs(d.y+d.height/2-d.startY)+d.width/2)
                        
                        } else {

                            return (d.x+d.width/2);

                        }
                    })
                    
                  .attr('y2', function(d,i) {

                    if ( Math.abs(d.startY-d.y-d.height/2) < Math.abs(d.startX-d.x-d.width/2) ){
                        
                        return (d.startY);
             
                    } else {

                        return (d.y+d.height/2);

                    }
                });
                    
                    //return (d.y+d.height/2)});

                linesThin2
                    .data(siteData)
                    .attr('x1', function(d,i) {
                        if ( Math.abs(d.startY-d.y-d.height/2) < Math.abs(d.startX-d.x-d.width/2) ){

                            return (d.x-Math.sign(d.x+d.width/2)*Math.abs(d.y+d.height/2-d.startY)+d.width/2);

                        } else {

                            return (d.x+d.width/2)

                        }
                    })
                        
                    .attr('y1', function(d,i) {
                        if ( Math.abs(d.startY-d.y-d.height/2) < Math.abs(d.startX-d.x-d.width/2) ){

                            return (d.startY)

                        } else {

                            return (d.y+d.height/2)

                        }

                        })
                        //return (d.y+d.height/2)});
                    .attr('x2', function(d,i) {
                    return (d.x+d.width/2)})
                    .attr('y2', function(d,i) {
                    return (d.y+d.height/2)});
              }

            const circles = tmsContainer.selectAll('.labeledCircle')
              .data(siteData)
              .join('circle')
              .attr('class', 'labeledCircle')
              .attr('r', 8 )
              .attr('cx', d => d.startX)
              .attr('cy', d => d.startY)
              .attr('stroke-width', 2 )
              .attr('stroke','black')
              .attr('fill', d => d3.select(d.element).select('rect').attr('fill'));

            const lines = tmsContainer.selectAll('.connecting-line')
                .data(siteData)
                .join('line')
                .attr('class', 'connecting-line')
                .attr('x1', d => d.startX)
                .attr('y1', d => d.startY)
                .attr('x2', d => d.startX)
                .attr('y2', d => d.startY)
                .attr('stroke-width', 7 )
                .attr('stroke', 'black')
                .attr("stroke-linecap", "round");

            const lines2 = tmsContainer.selectAll('.connecting-line2')
                .data(siteData)
                .join('line')
                .attr('class', 'connecting-line')
                .attr('x1', d => d.startX)
                .attr('y1', d => d.startY)
                .attr('x2', d => d.startX)
                .attr('y2', d => d.startY)
                .attr('stroke-width', 7 )
                .attr('stroke', 'black')
                .attr("stroke-linecap", "round");

            const linesThin = tmsContainer.selectAll('.connecting-line-thin')
                .data(siteData)
                .join('line')
                .attr('class', 'connecting-line-thin')
                .attr('x1', d => d.startX )
                .attr('y1', d => d.startY )
                .attr('x2', d => d.startX )
                .attr('y2', d => d.startY )
                .attr('stroke-width', 2 )
                .attr('stroke', d => d3.select(d.element).select('rect').attr('fill'))
                .attr("stroke-linecap", "round");

            const linesThin2 = tmsContainer.selectAll('.connecting-line-thin2')
                .data(siteData)
                .join('line')
                .attr('class', 'connecting-line-thin')
                .attr('x1', d => d.startX )
                .attr('y1', d => d.startY )
                .attr('x2', d => d.startX )
                .attr('y2', d => d.startY )
                .attr('stroke-width', 2 )
                .attr('stroke', d => d3.select(d.element).select('rect').attr('fill'))
                .attr("stroke-linecap", "round");

            // Define the custom collision function for rects(not done)

            //run simulation

            const simulation = d3.forceSimulation(siteData)
                //.alphaDecay(0.01)
                //.velocityDecay(0.6)
                //.force("collide", d3.forceCollide(customCollisionFunction).strength(1))
                .force('collide', d3.forceCollide().radius(function(d) {
                    return d.width / 2 + 8 ;
                }))
                .force("anchor1", d3.forceX(d => d.startX-d.width/2).strength(1))
                .force("anchor2", d3.forceY(d => d.startY-d.height/2).strength(2));
            
            for (let i = 0; i < 200; i++) {
                simulation.tick();
            }
                  

            simulation.on('tick', ticked);
            
            // Initialize a flag variable to keep track of the state
            let isFixed = false;
            // Set mouseover transision durationi ms
            const duration = 100;
            // Add a mouseover event listener to each 'g' element
            rects.on('mouseover', function(d) {

                if (isFixed) return;
                
                // Set the opacity of all other 'g' elements with the same class to a lower value
                rects.transition().duration(duration).style('opacity', 0.1);

                 // Filter 'lines' objects that have the same startX and startY as the parent of 'd.target'
                const startX = d3.select(d.target.parentNode).datum().startX;
                const startY = d3.select(d.target.parentNode).datum().startY;
                
                circles.filter(function(circle) {
                    return circle.startX !== startX || circle.startY !== startY;
                }).transition().duration(duration).style('opacity', 0.8);

                circles.filter(function(circle) {
                    return circle.startX === startX && circle.startY === startY;
                }).raise();

                const matchLine = lines.filter(function(line) {
                    return line.startX === startX && line.startY === startY;
                });
                
                lines.transition().duration(duration).style('stroke-opacity', 0.1)
                matchLine.transition().duration(duration).style('stroke-opacity',1);
                matchLine.raise();

                const matchLine2 = lines2.filter(function(line) {
                    return line.startX === startX && line.startY === startY;
                });

                lines2.transition().duration(duration).style('stroke-opacity', 0.1)
                
                matchLine2.transition().duration(duration).style('stroke-opacity',1);
                matchLine2.raise();

                const matchLineThin = linesThin.filter(function(line) {
                    return line.startX === startX && line.startY === startY;
                })
                
                linesThin.transition().duration(duration).style('stroke-opacity', 0.1);
                matchLineThin.transition().duration(duration).style('stroke-opacity', 1);
                matchLineThin.raise();

                const matchLineThin2 = linesThin2.filter(function(line) {
                    return line.startX === startX && line.startY === startY;
                })
                
                linesThin2.transition().duration(duration).style('stroke-opacity', 0.1);
                matchLineThin2.transition().duration(duration).style('stroke-opacity', 1);
                matchLineThin2.raise();

                d3.select(d.target.parentNode).transition().duration(duration)
                    .style('opacity',1);
                d3.select(d.target.parentNode).selectAll('text').transition().duration(duration)
                    .attr('font-weight','bold');
                d3.select(d.target.parentNode).raise();

            });
            // Add a click event listener to the 'g' elements
            rects.on('click', function(d) {
                // Update the state and set the opacity of all 'g' elements to full opacity
                if (!isFixed){

                } else if (isFixed) {
                
                // Set the opacity of all other 'g' elements with the same class to a lower value if the state is not fixed
                    rects.transition().duration(duration).style('opacity', 1);
                    circles.transition().duration(duration).style('opacity',1);
                    lines.transition().duration(duration).style('stroke-opacity',1);
                    linesThin.transition().duration(duration).style('stroke-opacity',1);
                    rects.raise();

                }

                isFixed = !isFixed;

            });
            
            // Add a mouseout event listener to each 'g' element
            rects.on('mouseout', function(d) {
                if (isFixed) return;
                // Reset the opacity of all 'g' elements with the common class to their original value
                rects.transition().duration(200).style('opacity', 1);
                circles.transition().duration(200).style('opacity',1);
                lines.transition().duration(200).style('stroke-opacity',1);
                linesThin.transition().duration(200).style('stroke-opacity',1);
                lines2.transition().duration(200).style('stroke-opacity',1);
                linesThin2.transition().duration(200).style('stroke-opacity',1);
                d3.select(d.target.parentNode).selectAll('text').transition().duration(duration)
                    .attr('font-weight','normal');
                rects.raise();
            });

            rects.raise();

        }

        const filename=`mitoatlas_topology_of_${genename}`

        const saveOptions = [

        { format: "svg", label: "Save as SVG" },
        { format: "jpeg", label: "Save as JPEG" },
        { format: "png", label: "Save as PNG" },
        { format: "pdf", label: "Save as PDF" }

        ];
        if(svgRef.current){
            saveButton(btncontainerRef, saveOptions, svgRef, calcWidth,calcHeight,filename);
        }

    },[ selectedTM,
        sites, 
        isOn,
        tmOptions,
        tm_manual, 
        tm_deeptmhmm, 
        tm_tmbed, 
        tm_uniprot, 
        genename,
        LocalER, 
        sequence, 
        style ]);

    

    return (<>
        {isoption &&
        <div style={{display:"flex",
        flexDirection:"column",
        flexFlow:"column wrap"}}
        ref={svgcontainerRef}>
            <select
                className="iframe-select"
                value={selectedTM}
                onChange={handleTMChange}
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '0px',
                    padding: '8px 16px',
                    fontSize: '20px',
                    fontFamily: 'Arial',
                    cursor: 'pointer',
                    position:'relative',
                    top:0,
                    width:400,
                }}
            >
                {tmOptions.map(options => (
                    <option key={options.value} value={options.value}>{options.label}</option>
                ))}
            </select>
            <div className="flip-switch_container"
            style={{
                display: "flex",
                flexDirection:"row",
                flexFlow:"row wrap"
            }}
            >
                <div style = {{marginRight:0,
                    padding: 5}}>N terminus direction:</div>
                <div className="flip-switch"
                    style = {{ padding: 5}}>
                <input
                    type="checkbox"
                    className="flip-switch-checkbox"
                    id="flipSwitch"
                    checked={isOn}
                    onChange={handleToggle}
                />
                <label className="flip-switch-label" htmlFor="flipSwitch">
                    <div className="flip-switch-inner" />
                    <div className="flip-switch-switch" />
                </label>
                </div>
            </div>
            <div>
                <svg ref={svgRef} >
                    
                </svg>
            </div>
            <div className='savebtn_container' ref={btncontainerRef}
                style={{
                    position:"relative",
                }}>
            </div>
        </div>}</>
    );
}  
