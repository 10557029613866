export default function Version(){
    return (
        <>

        <div className="subtitle">Version</div>
    
        <div className="link">
    
            <a href="#" className="disabled">spatiomics.org v0.10.1 last updated 25.02.07</a>
        
        </div>
      
        </>
    );
}