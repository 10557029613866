import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import SearchResult from './SearchResult';
import ProteinDetails from './ProteinDetails';
import ERDetails from './ERDetails';
import SarsCoV2Main from './SarsCoV2Main';
import MitoAtlasMain from './MitoAtlasMain';
import MoimMain from './MoimMain';
import ERMain from './ERMain';
import InteractionDetails from './InteractionDetails';

function MyRouter() {

  let subDomain = '';

  const handleSubdomainChange = () => {
    const currentHost = window.location.hostname;
    if (currentHost.split('.').length >= 2) {
      subDomain = currentHost.split('.')[0];
      // Update the Routes based on the new subdomain if needed
    }
  };

  // Listen for changes in the subdomain before the page unloads
  window.onbeforeunload = handleSubdomainChange;

  handleSubdomainChange(); // Initial subdomain detection

  return (
    <BrowserRouter>

      <Routes>

        { subDomain !== 'sarscov2' &&  subDomain !== 'mitoatlas' &&  subDomain !== 'moim' &&  subDomain !== 'er' &&  

        <Route path="/" element={<App />} />}

        { subDomain === 'sarscov2' &&
        
        <Route path="/" element={<SarsCoV2Main />} />

        }

        { subDomain === 'mitoatlas' &&
        
        <Route path="/" element={<MitoAtlasMain />} />

        }

        { subDomain === 'moim' &&
        
        <Route path="/" element={<MoimMain />} />

        }

        { subDomain === 'er' &&
        
        <Route path="/" element={<ERMain />} />

        }

        <Route path="/searchresult" element={<SearchResult />} />

        { subDomain !== 'moim' && subDomain !== 'er' && 
        
          <Route path="/:uid" element={<ProteinDetails />} />
        
        }

        { subDomain === 'er' && 
        
        <Route path="/:uid"  element={<ERDetails />} />
      
        }

        { subDomain === 'moim' && 
        
          <Route path="/:fileName" element={<InteractionDetails />} />
        
        }
        

      </Routes>

    </BrowserRouter>
  );
}

export default MyRouter;